@charset "UTF-8";
.masonry {
  margin: 0 30px; }

.page {
  padding: 116px 0 110px 0; }

.searchEnd {
  position: relative;
  height: 160px; }

.searchInputWrapper {
  padding: 22px 30px;
  background: #fff;
  display: flex;
  flex-direction: column; }
  .searchInputWrapper .inputWrapper {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    display: flex; }
  .searchInputWrapper .pinliteLogo {
    width: 56px;
    height: 56px;
    position: relative; }
  .searchInputWrapper:after {
    display: block;
    content: " ";
    background-size: 32px 10px;
    background-repeat: no-repeat;
    left: 70px;
    top: 22px;
    position: absolute;
    width: 32px;
    height: 10px; }
  .searchInputWrapper .inputBox {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 614px;
    height: 72px;
    padding: 0 72px;
    background: #FFFFFF;
    border-radius: 36px;
    border: 3px solid #E5E5E5; }
    .searchInputWrapper .inputBox:before {
      content: " ";
      display: block;
      width: 36px;
      height: 36px;
      position: absolute;
      left: 22px;
      top: 50%;
      transform: translate3d(0, -50%, 1PX);
      background: url("../../../assets/images/icon_pin_search.svg") no-repeat center center;
      background-size: 36px 36px; }
    .searchInputWrapper .inputBox .input {
      font-size: 30px;
      font-weight: 500;
      color: #333333;
      line-height: 45px;
      flex: 1;
      border: 0 none;
      outline: 0;
      border-radius: 0;
      -webkit-appearance: none;
      /*清除ios默认圆角*/ }
      .searchInputWrapper .inputBox .input::-webkit-input-placeholder {
        color: #AAAAAA; }
    .searchInputWrapper .inputBox .btnClear {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 72px;
      height: 72px;
      right: 10px;
      top: 50%;
      transform: translateY(-50%); }
      .searchInputWrapper .inputBox .btnClear svg {
        fill: #767676;
        width: 32px;
        height: 32px; }
