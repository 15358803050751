$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";
.item{
  display: flex;
  flex: 1;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  .grid{
    display: flex;
    flex: 1;
    border-radius: 30px;
    overflow: hidden;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    .imgCover{
      position: absolute;left:0; right:0; top:0; bottom:0;
    }
    img{
      max-width: 100%;
    }
  }
  .desc{
    height: 16+16+7px;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: flex-end;
    .btnMore{
      display: flex; justify-content: center; align-items: center;
      width: 39+16+16px; height: 39px; padding: 0 16px;
      img,svg{
        width:29px; height: 12px; fill: #767676;
      }
    }
  }
}

.popOverlay{
  background: rgba(0,0,0,0.5);
  z-index: 100 !important;
}
.popup{
  height: 374-80px;
  background: #fff;
  border-radius: 22px 22px 0px 0px !important;
  z-index: 105 !important;
  .h3{
    height: 44 + 44 + 28px; display: flex;
    justify-content: center; align-items: center;
    margin: 0;
    font-size: 28px;
    font-weight: 500;
    color: #101112;
    line-height: 42px;
  }
  > svg{
    width:34px; height: 34px; left:39px; top:44px; position: absolute;
  }
  .close {
    width:34px; height: 34px; left:39px; top:44px; position: absolute;
  }
  ul{
    margin-top: 21px;
    li{
      height: 28+38px;
      display: flex;
      padding-left: 36px;
      justify-content: flex-start;
      align-items: center;
      font-size: 28px;
      font-weight: 500;
      color: #101112;
      line-height: 42px;
      svg,img{
        fill: #101112; width:28px; height: 28px; margin-right: 16px;
      }
    }
  }
}
