$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";
.loginBox{
  height: 100px;
  border-radius: 24px 24px 0px 0px;
  background: #B2DCFE;
  box-shadow: 0px -8px 16px 0px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: row;
  padding: 0 30px 0 22px;
  align-items: center;
  position: fixed;
  left:0; right:0;bottom:0;
  transform: translate3d( 0, 0, 2PX );
  z-index: 1000;
  .loginBox-logo{
    width: 98px; height: 98px;
    background-image: url("../../../assets/images/icon-loginbox-pinterest2.svg");
    background-repeat: no-repeat;
    background-size: 98px 98px;
    flex-basis: 98px;
  }
  .loginBox-text{
    flex:1;
    font-size: 24px;
    font-weight: bold;
    color: #303030;
    line-height: 0px;
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    transform: translate(0,1px);
    p{
      margin: 0;
      line-height: 40px;
    }
    .loginBox-red{
      font-size: 26px;
      //font-weight: bold;
      color: #FF0000;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      letter-spacing: 1px;
      font-weight: bolder;
      position: relative;
      left:4px; top: 1px;
    }
  }
  .loginBox-btn{
    width: 220px;
    height: 66px;
    background: #E10600;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.3);
    border-radius: 48px;
    font-size: 34px;
    font-weight: bold;
    color: #FFFFFF;
    position: absolute;
    right: 30px;
    top: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}