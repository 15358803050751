$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";

.loginBox{
  height: 380px;
  background: linear-gradient(180deg, #B2DCFE 0%, #FFFFFF 100%);
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.3);
  position: fixed;
  left:0; right:0;bottom:0;
  padding: 68px 32px 0 32px;
  transform: translate3d( 0, 0, 2PX );
  z-index: 1000;
  .loginBox-con{
    padding-left: 180-32px; position: relative;
    .loginBox-logo{
      position: absolute;
      left:-16px;top:-16px;
      width:160px; height: 160px;
      background-image: url("../../../assets/images/icon-loginbox-pinterest.svg");
      background-repeat: no-repeat;
      background-size: 160px 160px;
    }
    .loginBox-text{
      font-size: 48px;
      font-weight: 500;
      color: #303030;
      line-height: 60px;
      justify-content: center;
      align-items: center;
      .loginBox-pin{
        color:#E50000;
      }
    }
  }
  .loginBox-line{
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 48px;
    .loginBox-btn{
      width: 442px;
      height: 96px;
      background: #E10600;
      border-radius: 48px;
      font-size: 48px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 0;
      letter-spacing: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover{
        &:after{
          content: " "; display: block;
        }
      }
    }
    .loginBox-login{
      position: absolute;
      right:-2px;
      top:50%;
      transform: translate( 0,-50% );
      opacity: 0.2;
      font-size: 48px;
      font-weight: bold;
      color: #000000;
      line-height: 72px;
      letter-spacing: 1px;
    }
  }

}